import React from 'react';
import parse from 'html-react-parser';

const FormattedText = ({text, color = "inherit"}) => {
  return (
    <div style={{color: color}} className="formatted-text">
      {text ? parse(text) : "NO TEXT" }
    </div>
  );
};

export default FormattedText;